import { useEffect, useState } from 'react'
import { get } from "services/actions";
import { useRouter } from 'next/router'
import strapiImg from "utils/strapiImg"
import AppLayout from 'components/AppLayout'
import styles from './404.module.css'


export default function Error404({appLayout}) {
  const router = useRouter()

  const path = router.asPath
 

  return (
    <AppLayout data={appLayout}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.backgroundParallelograms}>
            <div className={styles.parallelogramDetailRed} />
            <div className={styles.parallelogramDetailBlue} />
            <div className={styles.errorCode}>
              <p><b>OPS! :(</b></p>
              <p><b>ERRO 404</b></p>
            </div>
          </div>
          <div className={styles.textContent}>
            <div>
              <h1>Página não encontrada</h1>
            </div>
            <div>
              <span>O endereço abaixo não existe para realizaconstrutora.com.br</span>
              <span>{`https://www.realizaconstrutora.com.br${path}`}</span>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  )
}

export async function getStaticProps() {
  const header = await get("cabecalho");
  const footer = await get("rodape");

  // DADOS COMPONENTE: AppLayout
  const appLayout = {
    header: {
      logo: header?.logo ? strapiImg(header.logo, "", true) : "",
      menu: header?.menu ? header.menu : "",
    },
    footer: {
      logo: footer?.logo ? strapiImg(footer.logo, "", true) : "",
      redes: footer?.redes_sociais
        ? footer.redes_sociais.map(({ id, nome, link, icone }) => {
            icone = strapiImg(icone, "", true);
            return {
              id,
              nome,
              link,
              icone,
            };
          })
        : "",
      unidades: footer?.unidades ? footer.unidades : "",

      submenus: footer?.submenu ? footer.submenu : "",
      whatsappIcon: strapiImg(footer?.icone_whatsapp_rodape, "", true),
      openChatBot: footer?.habilitar_chatbot
    },
  }

  return {
    props: {
      appLayout
    }
  }
}
